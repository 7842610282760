import { html } from 'htm/preact';
import { useEffect } from 'preact/hooks';

export default function Home() {
    useEffect(() => {
        document.body.classList.add('home');
        document.body.classList.remove('about');
    }, []);

    return html`
        <div id="home" class="container">
            <header>
                <h1>CHILDREN OF LIGHT</h1>
                <a href="#!about">About</a>
            </header>
        </div>
    `;
}
