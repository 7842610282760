import Navigo from 'navigo';
import { render } from 'preact';
import { html } from 'htm/preact';
import About from './about';
import Home from './home';
import Kaleidoscope from './kaleidoscope';

export default class App {
    constructor() {
        this.router = new Navigo(null, true, '#!');
        this.app = document.getElementById('app');
        this.renderKaleidoscope();
        this.addRoutes();
        this.router.resolve();
    }

    renderKaleidoscope() {
        render(html`<${Kaleidoscope} />`, document.getElementById('background'));
    }

    addRoutes() {
        this.router.on({
            'about': () => render(html`<${About} />`, this.app)
        });
        this.router.on(() => render(html`<${Home} />`, this.app));
    }
}
