import title from '../assets/title.png';
import logoMobile from '../assets/logo-mobile.png';
import { html } from 'htm/preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import get from 'lodash/get';

export default function About() {
    const [data, setData] = useState({});
    const titleRef = useRef(null);

    const centerLogo = () => {
        if (! titleRef || ! titleRef.current) return;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const titleHeight = titleRef.current.offsetHeight;
        const margin = (viewportHeight / 2) - titleHeight - 36;
        titleRef.current.style.marginBottom = `${margin * 2}px`;
    };

    useEffect(centerLogo, [data]);

    useEffect(() => {
        document.body.classList.add('about');
        document.body.classList.remove('home');
        const debounceCenterLogo = debounce(centerLogo, 50);
        window.addEventListener('resize', debounceCenterLogo);
        return () => {
            window.removeEventListener('resize', debounceCenterLogo)
        };
    }, []);

    useEffect(() => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) return;
            if (xhr.status >= 200 && xhr.status < 300) {
                setData(JSON.parse(xhr.responseText));
            }
        };
        xhr.open('GET', 'data.json', true);
        xhr.send();
    }, []);

    return html`
        <div id="about" class="container">
            <section class="centered">
                <img class="title title-mobile" src="${logoMobile}" alt="CHILDREN OF LIGHT" />
                <img ref="${titleRef}" class="title" src="${title}" alt="CHILDREN OF LIGHT" />
                <div class="mission">
                    ${map(get(data, 'paragraphs', []), paragraph => html`<p>${paragraph}</p>`)}
                </div>
            </section>
            <section class="contact">
                <h2>${get(data, 'contact.title', 'Contact')}</h2>
                <ul>
                    ${map(get(data, 'contact.emails', []), ({ label, email }) => html`
                        <li>
                            ${ label && html`<h3>${label}</h3>`}
                            <a href="mailto:${email}">${email}</a>
                        </li>
                    `)}
                </ul>
            </section>
            ${ get(data, 'clients') && html`
                <section class="clients">
                    <h2>${get(data, 'clients.title', 'Selected Clients')}</h2>
                    <ul>
                        ${map(get(data, 'clients.desktop', []), clients => html`
                            <li class="clients-list">
                                ${map(clients, client => html`<span>${client}</span>`)}
                            </li>
                        `)}
                        ${map(get(data, 'clients.mobile', []), clients => html`
                            <li class="clients-list-mobile">
                                ${map(clients, client => html`<span>${client}</span>`)}
                            </li>
                        `)}
                    </ul>
                </section>
            `}
            <footer>
                <ul class="socials">
                    ${map(get(data, 'socials', []), ({ label, url }) => html`<li><a href="${url}">${label}</a></li>`)}
                </ul>
                <p class="copyright">${get(data, 'copyright', 'CHILDREN OF LIGHT © 2020')}</p>
            </footer>
        </div>
    `;
}
