import { html } from 'htm/preact';
import { Fragment } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import raf from 'raf';
import logo from '../assets/logo.png';
import debounce from 'lodash/debounce';

export default function Kaleidoscope() {
    const [backgroundPosition, setBackgroundPosition] = useState([0, 0]);
    const [mouseMoving, setMouseMoving] = useState(false);
    const isTouch = ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
    const requestRef = useRef();

    const animate = () => {
        if (mouseMoving) return false;
        const [x, y] = backgroundPosition;
        setBackgroundPosition([x + .6, y + 1]);
    };

    const onMouseMove = event => {
        if (isTouch) return false;
        setMouseMoving(true);
        let [x, y] = backgroundPosition;
        setBackgroundPosition([event.pageX, -y])
    };

    useEffect(() => {
        requestRef.current = raf(animate);
        return () => raf.cancel(requestRef.current);
    }, [backgroundPosition, mouseMoving]);

    useEffect(() => {
        const debounceResetMouseMoving = debounce(() => setMouseMoving(false), 25);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mousemove', debounceResetMouseMoving);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mousemove', debounceResetMouseMoving)
        }
    }, [])

    // Render tiles
    const tileNumber = 7;
    const tiles = [...Array(tileNumber * 2).keys()].map((value, index) => {
        const [x, y] = backgroundPosition;
        return html`
            <div class="tile t${index}" key="${index}">
               <div class="image" style="background-position: ${x}px ${y}px" />
            </div>
        `;
    });

    return html`
        <${Fragment}>
            <img src="${logo}" alt="CHILDREN OF LIGHT Logo" class="logo" />
            <div class="gradient"></div>
            <div class="kaleidoscope n${tileNumber}">
                ${tiles}
            </div>
        <//>
    `;
}
